import * as React from 'react';
interface PlatformProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  src?: string;
}
const PlatformIcon = React.forwardRef<HTMLImageElement, PlatformProps>(({
  src = '/PlatformDiagram.svg',
  alt = 'PlatformDiagram Icon',
  ...props
}, imgRef) => {
  return <img ref={imgRef} src={src} alt={alt} {...props} />;
});
const ForwardRef = PlatformIcon as React.ForwardRefExoticComponent<React.PropsWithoutRef<PlatformProps> & React.RefAttributes<HTMLImageElement>>;
export default ForwardRef;