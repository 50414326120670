'use client';

import { TextSecondaryWhite, SectionFormTextDark, SectionFormText } from '@/components/text';
import { ArrowRightIcon } from '@heroicons/react/20/solid';
import { ArrowUpRightIcon } from '@heroicons/react/24/outline';
import ReKernel from '@/icons/ReKernel';
import ReContract from '@/icons/ReContract';
import ReDapp from '@/icons/ReDapp';
import KernelLogo from '@/icons/KernelLogo';
import PlatformLogo from '@/icons/PlatformLogo';
import XLogo from '@/icons/x';
import DiscordLogo from '@/icons/Discord';
import { Heading1, Heading4xl } from '@/components/heading';
import { useRouter } from 'next/navigation';
import { useEffect } from 'react';
export default function QuickGuide() {
  const router = useRouter();
  useEffect(() => {
    router.prefetch('/kernel/register');
    router.prefetch('/contract/register');
    router.prefetch('/dapp/register');
  }, [router]);
  return <div className="mt-12 space-y-6" data-sentry-component="QuickGuide" data-sentry-source-file="quick-guide.tsx">
      <div className="space-y-4 rounded-2xl">
        <div className="flex items-center">
          <TextSecondaryWhite data-sentry-element="TextSecondaryWhite" data-sentry-source-file="quick-guide.tsx">Get Started</TextSecondaryWhite>
          <div className="h-6 w-6">
            <ArrowRightIcon className="text-white" data-sentry-element="ArrowRightIcon" data-sentry-source-file="quick-guide.tsx" />
          </div>
        </div>
        <div className="flex flex-col items-center justify-between gap-y-6 md:flex-row md:gap-x-6 md:gap-y-0">
          <div className="flex h-[175px] w-full cursor-pointer items-center justify-between rounded-2xl bg-shark-950 pl-6 shadow-alpha80 hover:scale-95 hover:bg-shark-900" onClick={() => router.push('/kernel/register')}>
            <div className="flex flex-col self-end pb-6">
              <TextSecondaryWhite className="select-none !text-xl/7 font-semibold" data-sentry-element="TextSecondaryWhite" data-sentry-source-file="quick-guide.tsx">
                Register your
              </TextSecondaryWhite>
              <Heading1 className="select-none italic" data-sentry-element="Heading1" data-sentry-source-file="quick-guide.tsx">kernels</Heading1>
            </div>
            <div className="my-auto">
              <ReKernel className="pointer-events-none w-full select-none" data-sentry-element="ReKernel" data-sentry-source-file="quick-guide.tsx" />
            </div>
          </div>
          <div className="flex h-[175px] w-full cursor-pointer items-center justify-between rounded-2xl bg-shark-950 pl-6 shadow-alpha80 hover:scale-95 hover:bg-shark-900" onClick={() => router.push('/contract/register')}>
            <div className="flex flex-col self-end pb-6">
              <TextSecondaryWhite className="select-none !text-xl/7 font-semibold" data-sentry-element="TextSecondaryWhite" data-sentry-source-file="quick-guide.tsx">
                Register your
              </TextSecondaryWhite>
              <Heading1 className="select-none italic" data-sentry-element="Heading1" data-sentry-source-file="quick-guide.tsx">
                smart contracts
              </Heading1>
            </div>
            <div className="my-auto">
              <ReContract className="pointer-events-none w-full" data-sentry-element="ReContract" data-sentry-source-file="quick-guide.tsx" />
            </div>
          </div>
          <div className="flex h-[175px] w-full cursor-pointer items-center justify-between rounded-2xl bg-shark-950 pl-6 shadow-alpha80 hover:scale-95 hover:bg-shark-900" onClick={() => router.push('/dapp/register')}>
            <div className="flex flex-col self-end pb-6">
              <TextSecondaryWhite className="select-none !text-xl/7 font-semibold" data-sentry-element="TextSecondaryWhite" data-sentry-source-file="quick-guide.tsx">
                Register your
              </TextSecondaryWhite>
              <Heading1 className="select-none italic" data-sentry-element="Heading1" data-sentry-source-file="quick-guide.tsx">dApps</Heading1>
            </div>
            <div className="my-auto">
              <ReDapp className="pointer-events-none w-full" data-sentry-element="ReDapp" data-sentry-source-file="quick-guide.tsx" />
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-full flex-col gap-x-6 gap-y-6 md:min-h-[568px] md:flex-row md:gap-y-0">
        <a className="relative min-h-[272px] w-full cursor-pointer overflow-hidden rounded-2xl bg-shark-950 p-6 hover:scale-95 md:w-1/2" target="_blank" href="https://docs.platform.lat">
          <div className="absolute inset-0 h-full w-full bg-black-linepattern bg-[size:150%] bg-centered sm:bg-[size:120%] md:bg-[size:150%] xl:bg-[size:100%]" />
          <div className="flex h-full flex-col justify-end">
            <SectionFormText className="select-none" data-sentry-element="SectionFormText" data-sentry-source-file="quick-guide.tsx">
              Documentation
            </SectionFormText>
            <Heading4xl className="z-10 select-none" data-sentry-element="Heading4xl" data-sentry-source-file="quick-guide.tsx">
              Learn more about the platform
            </Heading4xl>
            <div className="absolute right-4 top-4 h-6 w-6">
              <ArrowUpRightIcon className="h-full w-full cursor-pointer text-white" data-sentry-element="ArrowUpRightIcon" data-sentry-source-file="quick-guide.tsx" />
            </div>
          </div>
          <div className="absolute left-6 top-1/4 opacity-20 md:opacity-100 xl:top-[67px]">
            <PlatformLogo className="pointer-events-none" data-sentry-element="PlatformLogo" data-sentry-source-file="quick-guide.tsx" />
          </div>
        </a>
        <div className="w-full space-y-6 md:w-1/2">
          <div className="relative min-h-[272px] cursor-pointer rounded-2xl bg-gradient-white p-6 hover:scale-95">
            <a target="_blank" href="https://docs.krnl.xyz">
              <SectionFormTextDark className="select-none font-medium" data-sentry-element="SectionFormTextDark" data-sentry-source-file="quick-guide.tsx">
                Litepaper
              </SectionFormTextDark>
              <div className="absolute right-4 top-4 h-6 w-6">
                <ArrowUpRightIcon className="h-full w-full cursor-pointer text-black" data-sentry-element="ArrowUpRightIcon" data-sentry-source-file="quick-guide.tsx" />
              </div>
              <div className="absolute bottom-6 left-6 z-10 inline-block rounded-xl bg-black px-4 py-2">
                <Heading1 className="select-none" data-sentry-element="Heading1" data-sentry-source-file="quick-guide.tsx">What is KRNL</Heading1>
              </div>
              <div className="absolute bottom-0 left-0 select-none">
                <KernelLogo className="pointer-events-none" data-sentry-element="KernelLogo" data-sentry-source-file="quick-guide.tsx" />
              </div>
            </a>
          </div>
          <div className="flex w-full flex-col gap-x-6 gap-y-6 xl:flex-row xl:gap-y-0">
            <div className="relative flex min-h-[136px] w-full cursor-pointer flex-col justify-between rounded-2xl bg-combinedblue-gradient p-6 hover:scale-95 xl:min-h-[272px] xl:w-1/2">
              <div className="absolute inset-0 h-full w-full bg-[linear-gradient(180deg,_rgba(35,35,35,0)_0%,_#000000_120%)]" />
              <SectionFormText className="select-none font-medium" data-sentry-element="SectionFormText" data-sentry-source-file="quick-guide.tsx">
                News
              </SectionFormText>
              <div className="absolute right-4 top-4 h-6 w-6">
                <ArrowUpRightIcon className="h-full w-full cursor-pointer text-white" data-sentry-element="ArrowUpRightIcon" data-sentry-source-file="quick-guide.tsx" />
              </div>
              <TextSecondaryWhite className="z-10 select-none" data-sentry-element="TextSecondaryWhite" data-sentry-source-file="quick-guide.tsx">
                Stay tuned!
              </TextSecondaryWhite>
            </div>
            <div className="flex w-full flex-col gap-y-6 xl:w-1/2">
              <a className="relative flex min-h-[136px] w-full cursor-pointer flex-col justify-between rounded-2xl bg-combinedblue-gradient p-6 hover:scale-95 xl:min-h-[124px]" target="_blank" href="https://x.com/krnl_xyz">
                <div className="absolute inset-0 h-full w-full bg-[linear-gradient(180deg,_rgba(35,35,35,0)_0%,_#000000_120%)]" />
                <SectionFormText className="z-10 select-none font-medium" data-sentry-element="SectionFormText" data-sentry-source-file="quick-guide.tsx">
                  Follow us on
                </SectionFormText>
                <div className="absolute right-4 top-4 h-6 w-6">
                  <ArrowUpRightIcon className="h-full w-full cursor-pointer text-white" data-sentry-element="ArrowUpRightIcon" data-sentry-source-file="quick-guide.tsx" />
                </div>
                <TextSecondaryWhite className="z-10 select-none" data-sentry-element="TextSecondaryWhite" data-sentry-source-file="quick-guide.tsx">
                  X
                </TextSecondaryWhite>
                <div className="absolute bottom-2 right-2 select-none">
                  <XLogo className="pointer-events-none z-10" data-sentry-element="XLogo" data-sentry-source-file="quick-guide.tsx" />
                </div>
              </a>
              <a className="relative flex min-h-[136px] w-full cursor-pointer flex-col justify-between rounded-2xl bg-combinedblue-gradient p-6 hover:scale-95 xl:min-h-[124px]" target="_blank" href="https://discord.gg/byc84wU7SG">
                <div className="absolute inset-0 h-full w-full bg-[linear-gradient(180deg,_rgba(35,35,35,0)_0%,_#000000_120%)]" />
                <SectionFormText className="z-10 select-none font-medium" data-sentry-element="SectionFormText" data-sentry-source-file="quick-guide.tsx">
                  Chat with us on
                </SectionFormText>
                <div className="absolute right-4 top-4 h-6 w-6">
                  <ArrowUpRightIcon className="h-full w-full cursor-pointer text-white" data-sentry-element="ArrowUpRightIcon" data-sentry-source-file="quick-guide.tsx" />
                </div>
                <TextSecondaryWhite className="z-10 select-none" data-sentry-element="TextSecondaryWhite" data-sentry-source-file="quick-guide.tsx">
                  Discord
                </TextSecondaryWhite>
                <div className="absolute bottom-2 right-2 select-none">
                  <DiscordLogo className="pointer-events-none" data-sentry-element="DiscordLogo" data-sentry-source-file="quick-guide.tsx" />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>;
}