import * as React from 'react';
interface ReDappProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  src?: string;
}
const ReDappIcon = React.forwardRef<HTMLImageElement, ReDappProps>(({
  src = '/ReDapp.svg',
  alt = 'Register Dapp Icon',
  ...props
}, imgRef) => {
  return <img ref={imgRef} src={src} alt={alt} {...props} />;
});
const ForwardRef = ReDappIcon as React.ForwardRefExoticComponent<React.PropsWithoutRef<ReDappProps> & React.RefAttributes<HTMLImageElement>>;
export default ForwardRef;