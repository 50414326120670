import * as React from 'react';
interface ContractProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  src?: string;
}
const ReContractIcon = React.forwardRef<HTMLImageElement, ContractProps>(({
  src = '/ReContract.svg',
  alt = 'Register contract Icon',
  ...props
}, imgRef) => {
  return <img ref={imgRef} src={src} alt={alt} {...props} />;
});
const ForwardRef = ReContractIcon as React.ForwardRefExoticComponent<React.PropsWithoutRef<ContractProps> & React.RefAttributes<HTMLImageElement>>;
export default ForwardRef;