import * as React from 'react';
interface ReKernelProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  src?: string;
}
const ReKernelIcon = React.forwardRef<HTMLImageElement, ReKernelProps>(({
  src = '/ReKernel.svg',
  alt = 'Register Kernel Icon',
  ...props
}, imgRef) => {
  return <img ref={imgRef} src={src} alt={alt} {...props} />;
});
const ForwardRef = ReKernelIcon as React.ForwardRefExoticComponent<React.PropsWithoutRef<ReKernelProps> & React.RefAttributes<HTMLImageElement>>;
export default ForwardRef;