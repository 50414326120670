import clsx from 'clsx';
type HeadingProps = {
  level?: 1 | 2 | 3 | 4 | 5 | 6;
} & React.ComponentPropsWithoutRef<'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'>;
export function Heading4xl({
  className,
  level = 1,
  ...props
}: HeadingProps) {
  let Element: `h${typeof level}` = `h${level}`;
  return <Element {...props} className={clsx(className, 'text-4xl font-semibold text-zinc-950 dark:text-white sm:text-2xl/8')} data-sentry-element="Element" data-sentry-component="Heading4xl" data-sentry-source-file="heading.tsx" />;
}
export function Heading1({
  className,
  level = 1,
  ...props
}: HeadingProps) {
  let Element: `h${typeof level}` = `h${level}`;
  return <Element {...props} className={clsx(className, 'text-3xl/9 font-semibold text-zinc-950 dark:text-white sm:text-2xl/8')} data-sentry-element="Element" data-sentry-component="Heading1" data-sentry-source-file="heading.tsx" />;
}
export function Heading({
  className,
  level = 1,
  ...props
}: HeadingProps) {
  let Element: `h${typeof level}` = `h${level}`;
  return <Element {...props} className={clsx(className, 'text-2xl/8 font-semibold text-zinc-950 dark:text-white sm:text-xl/8')} data-sentry-element="Element" data-sentry-component="Heading" data-sentry-source-file="heading.tsx" />;
}
export function Subheading({
  className,
  level = 2,
  ...props
}: HeadingProps) {
  let Element: `h${typeof level}` = `h${level}`;
  return <Element {...props} className={clsx(className, 'text-base/7 font-semibold text-zinc-950 dark:text-white sm:text-sm/6')} data-sentry-element="Element" data-sentry-component="Subheading" data-sentry-source-file="heading.tsx" />;
}