import * as React from 'react';
interface XProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  src?: string;
}
const xIcon = React.forwardRef<HTMLImageElement, XProps>(({
  src = '/x.svg',
  alt = 'X Icon',
  ...props
}, imgRef) => {
  return <img ref={imgRef} src={src} alt={alt} {...props} />;
});
const ForwardRef = xIcon as React.ForwardRefExoticComponent<React.PropsWithoutRef<XProps> & React.RefAttributes<HTMLImageElement>>;
export default ForwardRef;