'use client';

import { Button } from '@/components/button';
import { NofixContentLayout } from '@/components/complete/contentLayout';
import { LoadingButton } from '@/components/custom/util';
import React, { useEffect, useMemo, useState } from 'react';
import { useAccount, useReadContract, useWaitForTransactionReceipt, useWriteContract } from 'wagmi';
import { faucetContractConfig, tokenContractConfig } from '@/config/contract-config';
import { formatEther, parseEther } from 'viem';
import { Heading1 } from '@/components/heading';
import { TextDescription } from '@/components/text';
import { Square2StackIcon } from '@heroicons/react/24/outline';
import { PlusIcon } from '@heroicons/react/16/solid';
import { CheckIcon } from '@heroicons/react/20/solid';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/custom/tooltipv2';
export function HomeContentLayout({
  children
}: {
  children: React.ReactNode;
}) {
  const {
    address,
    isConnected
  } = useAccount();
  const [hydrated, setHydrated] = useState(false);
  const [text, setText] = useState('Copy');
  const [icon, setIcon] = useState(false);
  const [truncatAddress, setTruncatAddress] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const {
    data: krnlBalance
  } = useReadContract({
    ...tokenContractConfig,
    functionName: 'balanceOf',
    args: [address as `0x${string}`]
  });
  const {
    writeContract: writeFaucetContract
  } = useWriteContract();
  const krnlBalanceinString = useMemo(() => {
    const balanceInEther = formatEther(krnlBalance || parseEther('0'));
    return parseFloat(balanceInEther).toFixed(4);
  }, [krnlBalance]);
  useEffect(() => {
    setHydrated(true);
  }, []);
  const requestKRNL = async () => {
    setIsLoading(true);
    await writeFaucetContract({
      ...faucetContractConfig,
      functionName: 'requestTokens',
      args: []
    }, {
      onError: () => {
        setIsLoading(false);
      },
      onSuccess: () => {
        setIsLoading(false);
      }
    });
  };
  useEffect(() => {
    if (address) {
      setTruncatAddress(`${address.slice(0, 6)}...${address.slice(-4)}`);
    }
  }, [address]);
  const copyToClipboard = () => {
    navigator.clipboard.writeText(address || '').then(() => {
      setText(() => 'Copied!');
      setIcon(() => !icon);
      // alert('Address copied to clipboard!');

      setTimeout(() => {
        setText(() => 'Copy');
        setIcon(() => icon);
      }, 600);
    });
  };
  return <>
      <NofixContentLayout bg="bg-backgroundInteractive" bgVector="bg-vector-blue" maxwidth="max-w-screen-2xl" data-sentry-element="NofixContentLayout" data-sentry-source-file="home-layout.tsx">
        {isConnected ? <div className="flex w-full items-center justify-between space-x-4 pt-[60px] text-white">
            {/* lg:space-x-60 xl:space-x-96 */}
            <div className="flex items-center space-x-4">
              {/* Address */}
              <div className="hidden items-center space-x-2 lg:flex">
                <p className="whitespace-nowrap text-2xl lg:text-3xl">
                  {truncatAddress}
                </p>
                <Tooltip placement="bottom">
                  <TooltipTrigger className="z-40 cursor-pointer">
                    <Button onClick={copyToClipboard} className="cursor-pointer rounded-lg p-1" style={{
                  background: 'rgba(255, 255, 255, 0.10)',
                  backdropFilter: 'blur(4px)',
                  borderRadius: '8px'
                }}>
                      {icon ? <CheckIcon className="h-4 w-4 fill-madang-200" /> : <Square2StackIcon className="h-4 w-4 text-gray-500" />}
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent className="z-50 max-w-[calc(100vw-10px)] rounded-md bg-shark-800 px-2 py-1 text-white" arrowClassName="fill-shark-800">
                    {text}
                  </TooltipContent>
                </Tooltip>
              </div>
            </div>

            {/* Total Balance */}
            <div className="flex flex-col justify-center text-right">
              <p className="text-xs opacity-70 sm:text-sm lg:text-base">
                Total Balance
              </p>
              <div className="flex flex-row gap-2">
                <Tooltip placement="bottom">
                  <TooltipTrigger className="z-40 cursor-pointer">
                    <Button onClick={requestKRNL} className="cursor-pointer rounded-lg" disabled={isLoading} style={{
                  background: 'rgba(255, 255, 255, 0.10)',
                  backdropFilter: 'blur(4px)',
                  borderRadius: '8px'
                }}>
                      <PlusIcon className="h-4 w-4 text-gray-500" />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent className="z-50 max-w-[calc(100vw-10px)] rounded-md bg-shark-800 px-2 py-1 text-white" arrowClassName="fill-shark-800">
                    Get Free Token
                  </TooltipContent>
                </Tooltip>
                <p className="text-2xl font-semibold lg:text-3xl">
                  {krnlBalanceinString} KRNL
                </p>
              </div>
            </div>
          </div> : <div className="w-full pt-[60px]">
            <Heading1>Home</Heading1>
            <TextDescription>
              Connect your wallet to get started
            </TextDescription>
          </div>}
      </NofixContentLayout>
      <div className="mx-auto w-full max-w-screen-2xl p-8">{children}</div>
    </>;
}