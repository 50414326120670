import * as React from 'react';
import { useFloating, autoUpdate, offset, flip, shift, useHover, useFocus, useDismiss, useRole, useInteractions, useMergeRefs, FloatingPortal, arrow, FloatingArrow, useTransitionStyles } from '@floating-ui/react';
import type { Placement } from '@floating-ui/react';
interface TooltipOptions {
  initialOpen?: boolean;
  placement?: Placement;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  ARROW_WIDTH?: number;
  ARROW_HEIGHT?: number;
}
export function useTooltip({
  initialOpen = false,
  placement = 'top',
  open: controlledOpen,
  onOpenChange: setControlledOpen,
  ARROW_WIDTH = 16,
  ARROW_HEIGHT = 8
} = {}) {
  const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen);
  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;
  const arrowRef = React.useRef(null);
  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [offset(12), flip({
      crossAxis: placement.includes('-'),
      fallbackAxisSideDirection: 'start',
      padding: 5
    }), shift({
      padding: 0
    }), arrow({
      element: arrowRef
    })]
  });

  //arrow
  const middlewareData = data.middlewareData;
  const arrowX = middlewareData.arrow?.x ?? 0;
  const arrowY = middlewareData.arrow?.y ?? 0;
  const transformX = arrowX + ARROW_WIDTH / 2;
  const transformY = arrowY + ARROW_HEIGHT;
  const context = data.context;
  const hover = useHover(context, {
    move: false,
    enabled: controlledOpen == null
  });
  const focus = useFocus(context, {
    enabled: controlledOpen == null
  });
  const dismiss = useDismiss(context);
  const role = useRole(context, {
    role: 'tooltip'
  });
  const interactions = useInteractions([hover, focus, dismiss, role]);
  return React.useMemo(() => ({
    open,
    setOpen,
    arrowRef,
    transformX,
    transformY,
    ARROW_WIDTH,
    ARROW_HEIGHT,
    ...interactions,
    ...data
  }), [open, setOpen, interactions, data, arrowRef, transformX, transformY, ARROW_WIDTH, ARROW_HEIGHT]);
}
type ContextType = ReturnType<typeof useTooltip> | null;
const TooltipContext = React.createContext<ContextType>(null);
export const useTooltipContext = () => {
  const context = React.useContext(TooltipContext);
  if (context == null) {
    throw new Error('Tooltip components must be wrapped in <Tooltip />');
  }
  return context;
};
export function Tooltip({
  children,
  placement = 'top',
  ...options
}: {
  children: React.ReactNode;
} & TooltipOptions) {
  // This can accept any props as options, e.g. `placement`,
  // or other positioning options.
  const tooltip = useTooltip({
    ...options,
    placement
  });
  return <TooltipContext.Provider value={tooltip} data-sentry-element="unknown" data-sentry-component="Tooltip" data-sentry-source-file="tooltipv2.tsx">
      {children}
    </TooltipContext.Provider>;
}
export const TooltipTrigger = React.forwardRef<HTMLElement, React.HTMLProps<HTMLElement> & {
  asChild?: boolean;
}>(function TooltipTrigger({
  children,
  asChild = false,
  ...props
}, propRef) {
  const context = useTooltipContext();
  const childrenRef = (children as any).ref;
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

  // `asChild` allows the user to pass any element as the anchor
  if (asChild && React.isValidElement(children)) {
    return React.cloneElement(children, context.getReferenceProps({
      ref,
      ...props,
      ...children.props,
      'data-state': context.open ? 'open' : 'closed'
    }));
  }
  return <div ref={ref}
  // The user can style the trigger based on the state
  data-state={context.open ? 'open' : 'closed'} {...context.getReferenceProps(props)}>
      {children}
    </div>;
});
export const TooltipContent = React.forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement> & {
  arrowClassName?: string;
}>(function TooltipContent({
  style,
  arrowClassName,
  ...props
}, propRef) {
  const context = useTooltipContext();
  const ref = useMergeRefs([context.refs.setFloating, propRef]);
  if (!context.open) return null;
  return <FloatingPortal>
      <div ref={ref} style={{
      ...context.floatingStyles,
      ...style
    }} {...context.getFloatingProps(props)}>
        {props.children}
        <FloatingArrow ref={context.arrowRef} context={context.context} width={context.ARROW_WIDTH} height={context.ARROW_HEIGHT} className={arrowClassName} />
      </div>
    </FloatingPortal>;
});