import * as React from 'react';
interface krnlLogoProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  src?: string;
}
const KrnlLogo = React.forwardRef<HTMLImageElement, krnlLogoProps>(({
  src = '/krnlLogo.svg',
  alt = 'dApp Icon',
  ...props
}, imgRef) => {
  return <img ref={imgRef} src={src} alt={alt} {...props} />;
});
const ForwardRef = KrnlLogo as React.ForwardRefExoticComponent<React.PropsWithoutRef<krnlLogoProps> & React.RefAttributes<HTMLImageElement>>;
export default ForwardRef;