import * as React from 'react';
interface DiscordProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  src?: string;
}
const DiscordIcon = React.forwardRef<HTMLImageElement, DiscordProps>(({
  src = '/discord.svg',
  alt = 'Discord Icon',
  ...props
}, imgRef) => {
  return <img ref={imgRef} src={src} alt={alt} {...props} />;
});
const ForwardRef = DiscordIcon as React.ForwardRefExoticComponent<React.PropsWithoutRef<DiscordProps> & React.RefAttributes<HTMLImageElement>>;
export default ForwardRef;